<template lang="html">

    <section class="rewards">
        <hero-global img="/img/premios.svg" title="Premios" subtitle="Premios CERMI.es" />
        <div class="box-content container">
            <ul class="menu-list container">
                <li v-for="(reward, key) in Object.values(store.rewards).slice(0,10)" :key="key" :value="key">
                    <router-link :class="[idParams == reward.id ? 'router-link-active' : '']" :to="{
            name: 'premios-cermi',
            params: {
              slug: reward.slug
            }
          }" title="Ver {{reward}}" alt="Ver {{reward}}">
                        {{ reward.name }}
                    </router-link>
                </li>
            </ul>
            <select name="menu-anclas-responsive" id="menu-anclas-responsive" class="select-anclas-responsive" v-model="menuDesplegable" :activeMenuTop="menuTopAnclas">
                <option v-for="(reward, key) in Object.values(store.rewards).slice(0,10)" :key="key" :value=" reward.slug">
                    {{ reward.name }}
                </option>
            </select>
            <div class="content container" v-if="awardSelected">
                <div class="grid-full">
                    <p class="title" v-if="awardSelected.name">{{awardSelected.name}}</p>
                    <p class="subtitle" v-if="awardSelected.header">{{awardSelected.header}}</p>
                    <EditorJs :object="awardSelected.body" />
                </div>

            </div>
        </div>
        <carrousel-2 class="container m-bottom-40-px" :dataContent="$tm('translations.carrouselData.cermi')" />
        <other-links-interest class="links-interest-box container" />
    </section>
</template>

<script lang="js">
    import HeroGlobal from "@/components/Global/HeroGlobal";
    import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
    import Carrousel2 from "@/components/Global/Carrousel2";
    import moment from 'moment'
    import { contentStore } from '@/stores/contents'
    import EditorJs from "@/components/Global/editorJs.vue"
    export default {
        name: 'rewards',
        props: ['id', 'slug'],
        components: {
            OtherLinksInterest,
            HeroGlobal,
            Carrousel2,
            EditorJs,
        },
        setup() {
            const store = contentStore();
            store.loadRewards();
            return {
                store,
            }

        },
        mounted() {
             this.menuDesplegable = this.slug
        },
        data() {
            return {
                menuDesplegable: ''
            }
        },
        methods: {
        },
        computed: {
            awardSelected() {
                let award = null
                if (this.store.rewards[this.slug]) {
                    award = this.store.rewards[this.slug]
                }
                return award
            }

        },
        watch: {
            "menuDesplegable"() {
                this.$router.push({
                    name: 'premios-cermi',
                    params: {
                        slug: this.menuDesplegable
                    }
                });
            }
        }
    }


</script>

<style scoped lang="scss">
    .box-date {
        p {
            font-weight: 300;
            padding-left: 30px;
            margin-bottom: 15px;
        }

        .category-location {
            background-size: 18px;
        }
    }
</style>